* {
    margin: 0;
    padding: 0;
  }
  // .tooltip-box-container{
  //   width: 100%;
  //   padding-bottom: 56.25%;
  // }
  
  .dot-scatter-legend {
    stroke: #000;
  }
  .tooltip-container{
    width: 100%;
    padding-top: 56.25%;
    position: relative;
  }
  .tooltip-box-scatter{
    top: 0;
    left: 0;
    position: absolute;
    font-size: 1rem;
    pointer-events: none;
    background-color: rgba(255, 255, 255, 0.90);
    // border: 1px solid black;
    border-radius: 0.3rem;
    padding: 0rem 0rem 0rem 0;
    box-shadow: 2px 2px 4px 1px darkgrey;
    color: black;
    z-index: 10000000;
  }
  .legend-flex-mekko{
    display: flex;
    margin-top: -20px;
    margin-bottom: 2rem;
    // padding-bottom: 2rem;
  }
  .tooltip-scatter {
    position: absolute;
    // margin-left: 1.1rem;
    font-size: 1rem;
    pointer-events: none;
    background-color: rgba(255, 255, 255, 0.90);
    // border: 1px solid black;
    border-radius: 0.3rem;
    padding: 0 0rem 0rem 0;
    // box-shadow: 2px 2px 4px 1px black;
    color: black;
    z-index: 10000000;
  }
  
  .tool {
    border: 3px solid red;
  }
  
  .axis-label {
    // background-color: #4472c4;
    // color: white;
    padding: 0.5rem;
    font-size: 0.95rem;
    font-weight: 400;
  }
  
  // .xAxis {
  //   position: absolute;
  
  //   margin-top:-3rem;
  //   margin-left: 45rem;
  //   text-align: center;
  
  // }
  .yAxis {
    padding: 1rem;
    position: absolute;
    margin-top: -19.5rem;
    margin-right: 3rem;
    text-align: center;
  
    transform: rotate(-90deg);
  
  }
  
  .stage {
    padding: 1rem;
    border: 1px solid red;
  }
  
  .tooltip-box-scatter {
    padding-top: 0.5rem;
  }
  
  .flex-container,
  .flex-container-spaced,
  .flex-container-nowrap {
    font-size: 0.85rem;
    width: 37rem;
    display: flex;
    // margin: 0.5rem;
    justify-content: space-around;
    border-top: 1px solid lightgrey;
    flex-wrap: wrap;
  
  }
  .flex-container-spaced{
    // justify-content: space-around;
    border-bottom: 1px solid lightgrey;
  }
  
  .flex-item {
    // border: 1px solid black;
    padding-right: 5px;
    margin: 0.25rem;
  }
  
  // .flex-item {
  //   margin-left: 0.5rem;
  // }
  
  .heading-style {
    font-weight: 600;
  }
  .tooltip-table td{
    border: 1px solid #333;
    border-top: 1px solid #333;
    border-bottom: 1px solid #333;
  }
  .tooltip-table td{
    padding: 0.5rem;
    // min-width: 8rem;
  }
  
  @media (max-width:600px){
    .flex-container,
    .flex-container-spaced {
      width: 10rem;
    }  
  }
  @media (min-width:601px) and (max-width:960px){
    .flex-container,
    .flex-container-spaced {
      width: 20rem;
    }  
  }
  .grid{
    stroke: gray;
    stroke-width: 1px;
  }
  .border-right{
    border-right: 1px solid black;
  }
  .flex-container-nowrap{
    flex-wrap: nowrap;
  }