html {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  /* font-size: 0.8em !important; */
}

/* @media screen and (min-width: 80em) {
  html {
    font-size: calc(0.8em + 0.25 * (100vw - 80em) / 40);
    font-size: -webkit-calc(80% + 0.25 * (100vw - 8000%) / 40);
    font-size: -ms-calc(0.8em + 0.25 * (100vw - 80em) / 40);
  }
}

@media screen and (min-width: 120em) {
  html {
    font-size: 1em !important;
  }
} */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  width: 100%;
  height: 100%;
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}