// ag-grid.scss defines structural styles and should be imported first
@import "~ag-grid-community/src/styles/ag-grid.scss";
@import "~ag-grid-community/src/styles/ag-theme-alpine/sass/ag-theme-alpine-mixin.scss";

@mixin fontFamily ($value) {
  font-family: $value !important;
}

.closed_funnel_table_v2 {
  @include ag-theme-alpine((odd-row-background-color: #f5f5f5,
      row-border-color: lightgrey,
      row-hover-color: null,
      checkbox-checked-color: 'white',
      range-selection-border-color: "white",
      range-selection-background-color: white,
      borders: true,
      cell-horizontal-border: solid lightgrey,
      header-column-separator: true,
      header-column-separator-height: 100%,
      header-column-separator-width: 1px,
      header-column-separator-color: ag-derived(border-color, $opacity: 0.5),
      grid-size: 0px,
      font-size: 0.85rem ,
      cell-horizontal-padding: ag-derived(grid-size, $times: 2)
    ));

  @include fontFamily(inherit);
 
  .ag-tooltip {
      background-color: white !important;
      border-radius: 5px !important;
      padding: 5px !important;
      border-width: 1px !important;
      border-style: solid !important;
      border-color: #545454 !important;
      height:50px !important;
      line-height: 30px !important;
      opacity:0.9;
      font-weight: 600;
      font-size: 0.87rem;
      color:black;
      border-top-color: rgb(191, 143, 0) !important;
      border-top-width:10px !important ;
      transform: translateX(-6.5rem) translateY(-2rem);
}

  .ag-cell,.ag-cell-not-inline-editing, .ag-cell-auto-height{
height:31px;
  }

//   .ag-header-cell-text{
//     font-size: 0.85rem !important;

//   }
  // .ag-row-odd {
  //   height: 0;
  //   transform: translateY(100px);  }
.ag-cell{
  line-height: 17px;
}

   .ag-row-odd ,.ag-row-position-absolute {
    // height: 28px;
    // transform: translateY(30px);
    font-size: 0.85rem !important;
    // width:65rem;
  }
//   .ag-header-cell,.ag-focus-managed {
//     //   width: 100%;
//   }

.ag-header-group-cell-label,.ag-header-cell,.ag-focus-managed {
  justify-content: center;
}

  .ag-header-cell-label {
    justify-content: center;
  

  }
  .ag-header-cell-label .ag-header-cell-text {
    justify-content: center !important;
    white-space: pre-wrap !important;
    text-align: center;
 }
 
  
}