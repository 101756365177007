img {
  width: 220px
}

.pendulum{
    position:absolute; 
    width: 220px;
    height: 180px;
    top:50%;
    left:  50%;
    border-radius: 5%;
    align-items: center;
    transform: translate(-50%, -50%);
    }
  .pendulum_box{
    display: flex;
    padding: 10px 0 0 75px;
    position: absolute;
    flex: 1;
  }
  
  .ball{
    height: 15px;
    width: 15px;
    border-radius: 50%;
    // background-color: #455681;
    position: relative;
    transform-origin: 50% -300%;
    
  }
    .ball::before{
      height: 120px;
      left: 18px;
      top: -120px;
      position: absolute;
    }
  
  .ball.first{
    animation: firstball .9s alternate ease-in infinite;
  }

  .ball.sk1{
      background-color: #4472c4;
  }

  .ball.sk2{
    background-color: #70ad47;
  }
  
  @keyframes firstball{
    0%{
      transform: rotate(35deg);
    }
    50%{
      transform: rotate(0deg);
    }
  }
  
  .ball.last{
    animation: lastball .9s alternate ease-out infinite;
  }
  
  @keyframes lastball{
    50%{
      transform: rotate(0deg);
    }
    100%{
      transform: rotate(-35deg);
    }
  }