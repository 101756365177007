.bar {
  fill: #70ad47;
  border:5px solid black;
  // stroke: hsl(96, 42, 20);
  // stroke-width: 1.5px;
}

.axis {
  font-size: 13px;
}

.axis path,
.axis line {
  fill: none;
  display: none;
}

.label,.yaxis {
  font-size: 0.85rem !important;
}
.dot{
  font-weight: 400 !important;
}
.ticks{
  text-transform: capitalize;
}
.legend-text{
  margin: 0;
}
*{
  margin: 0;
  padding: 0;
}
.dot {
  stroke: #000;
}
.tooltip {
  position: absolute;
  // margin-left: 1.1rem;
  font-size: 1rem;  
  pointer-events: none;
  background-color: rgba(255, 255, 255, 0.90);
  // border: 1px solid black;
  border-radius: 1rem;
  padding: 0 0rem 0rem 0;
  box-shadow: 2px 2px 4px 1px darkgrey;
  color: black;
  z-index: 10000000;
}
.tool {
  border: 3px solid red;
}
.axis-label {
  // background-color: #4472c4;
  // color: white;
  padding: 0.5rem;
  font-size: 0.85rem !important;
  font-weight: 400;
}
// .xAxis {
//   position: absolute;
  
//   margin-top:-3rem;
//   margin-left: 45rem;
//   text-align: center;
  
// }
.yAxis {
  padding: 1rem;
  position: absolute;
  margin-top:-19.5rem;
  margin-right: 3rem;
  text-align: center;
  
  transform:rotate(-90deg);
  
}
.stage{
  padding: 1rem;
  border:1px solid red;
}
.tooltip-box{
  padding: 1.25rem;
}
.flex-container{
  display: flex;
  margin: 0.5rem;
}
.flex-item-team{
  margin-right: 1rem;
}
.flex-item-stage{
  margin-left: 1rem;
}
.heading-style{
  text-align: center;
  font-weight: 600;
}
.container{
    margin: 5px;
}
.result-style{
  text-align: left;
  font-weight: 600;
}
