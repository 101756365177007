.grid line {
    stroke: lightgrey;
    stroke-opacity: 0.7;
  }
  
  .grid path {
    stroke-width: 0;
    stroke: none;
  }
  
  .domain{
    stroke-width: 1;
    stroke: lightgrey;
  }
  
  .tick {
    stroke: none;
  }