.tooltip-funeel-grid-container{
    font-size: 0.75rem; 
    margin: 0.5rem;
     min-width: 10rem;  
     display: grid; 
    //  grid-template-columns: auto auto; 
     border-top: 1px solid lightgrey;
  }

  .helper-tooltip{
    font-size: 0.75rem; 
    margin: 0.5rem;
     min-width: 8rem;  
     display: grid; 
    //  grid-template-columns: auto auto; 
    //  border-top: 1px solid lightgrey;
  }
  .helper-tooltip-container{    
     display: flex; 
     flex-wrap: wrap;
     align-items: center;
    justify-content: center;
  }
  .helper-tooltip-array-winrate{
    width: 13rem;
  }
  .helper-tooltip-array{
    width: 17rem;
  }
  .helper-item{     
    padding: 0.3rem 0.6rem ;
  }

  .font-bold{
      font-weight: 600;
  }
  