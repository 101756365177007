.repPerformance:last-child td{
    font-weight: 800 !important;
    // background-color: yellowgreen;

    
}
// .repPerformance:nth-child(2) th{
//     width: 400px !important;
// }

