// ag-grid.scss defines structural styles and should be imported first
@import "~ag-grid-community/src/styles/ag-grid.scss";
@import "~ag-grid-community/src/styles/ag-theme-alpine/sass/ag-theme-alpine-mixin.scss";

@mixin fontFamily($value) {
  font-family: $value !important;
}

.quota_maintance {
  @include ag-theme-alpine(
    (
      odd-row-background-color: #f5f5f5,
      row-border-color: lightgrey,
      row-hover-color: null,
      checkbox-checked-color: #2661ad,
      range-selection-border-color: #ff00b1,
      range-selection-background-color: #03305633,
      borders: true,
      cell-horizontal-border: solid lightgrey,
      header-column-separator: true,
      header-column-separator-height: 100%,
      header-column-separator-width: 1px,
      header-column-separator-color: ag-derived(border-color, $opacity: 0.5),
      grid-size: 4px,
      font-size: 0.85rem,
      cell-horizontal-padding: ag-derived(grid-size, $times: 3),
    )
  );

  @include fontFamily(inherit);

  //   .ag-header-cell-text{
  //     font-size: 0.85rem !important;

  //   }
  //   .ag-row-odd {
  //     height: 0;
  //     transform: translateY(0);  }

  .ag-row-odd,
  .ag-row-position-absolute {
    // height: 28px;
    transform: translateY(28px);
    font-size: 0.85rem !important;
    // width:65rem;
  }
  .ag-header-cell,
  .ag-focus-managed {
    width: 85.7rem;
  }

  //   .ag-cell {
  //     line-height: 15px;
  //     width:100rem

  //   }

  //   .ag-menu-option-active {
  //     background-color: #2661ad;
  //     color: white;
  //   }

  .ag-header-cell-label {
    justify-content: center;
  }

  // .ag-header-cell-text {
  //   white-space: break-spaces;
  // }

  //   .ag-header-cell {
  //     background-color: #4472c4;
  //     color: white;
  //     font-size: 0.85rem;
  //   }

  //   .ag-header-group-cell {
  //     &[col-id='1_0'] {
  //       background: #B4C6E7;
  //     }

  //     &[col-id='2_0'] {
  //       background: #FFD966;
  //     }

  //     &[col-id='3_0'] {
  //       background: #F8CBAD;
  //     }

  //     &[col-id='4_0'] {
  //       background: #5B9BD5;
  //     }

  //     &[col-id='5_0'] {
  //       background: #FFC000;
  //     }

  //     &[col-id='6_0'] {
  //       background: #FFE699;
  //     }
  //   }

  //   .ag-row-pinned {
  //     .ag-cell {
  //       &[col-id='industry'] {
  //         font-weight: bold;
  //       }

  //       &[col-id^='Lost_'] {
  //         background: #ED7D31;
  //         color: white;
  //       }

  //       &[col-id^='%Lost_'] {
  //         background: #ED7D31;
  //         color: white;
  //       }

  //       &[col-id^='Won_'] {
  //         background: #70AD47;
  //         color: white;
  //       }

  //       &[col-id^='%Won_'] {
  //         background: #70AD47;
  //         color: white;
  //       }

  //       &[col-id^='Targeted_'] {
  //         background: #8497B0;
  //         color: white;
  //       }

  //       &[col-id^='%Total_'] {
  //         background: #8497B0;
  //         color: white;
  //       }
  //     }
  //   }
}

.custom-tooltip {
  width: 150px;
  height: 70px;
  border: 1px solid cornflowerblue;
  overflow: hidden;
}

.custom-tooltip p {
  margin: 5px;
  white-space: nowrap;
}

.custom-tooltip p:first-of-type {
  font-weight: bold;
}
.not-updated {
  background-color: #f4b183 !important;
}
.edit-bg {
  background-color: #d0deefff !important;
}
