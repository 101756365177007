.arrowdiv {
    width: 250px;
    height: 40px;
    position: relative;
    background: #d9ead3;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
  }
  .arrowdiv:before {
    content: "";
    position: absolute;
    right: -20px;
    bottom: 0;
    width: 0;
    height: 0;
    border-left: 20px solid #d9ead3;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
  }