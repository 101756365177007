// ag-grid.scss defines structural styles and should be imported first
@import "~ag-grid-community/src/styles/ag-grid.scss";
@import "~ag-grid-community/src/styles/ag-theme-alpine/sass/ag-theme-alpine-mixin.scss";

@mixin fontFamily ($value) {
  font-family: $value !important;
}

.ag-theme-custom-react-pbi {
  @include ag-theme-alpine((odd-row-background-color: #f5f5f5,
      row-border-color: lightgrey,
      row-hover-color: null,
      checkbox-checked-color: #2661ad,
      range-selection-border-color: #ff00b1,
      range-selection-background-color: #03305633,
      borders: true,
      cell-horizontal-border: solid lightgrey,
      header-column-separator: true,
      header-column-separator-height: 100%,
      header-column-separator-width: 1px,
      header-column-separator-color: ag-derived(border-color, $opacity: 0.5),
      grid-size: 4px,
      font-size: 0.85rem,
      cell-horizontal-padding: 2px,
    ));

    @include fontFamily(inherit);

  .ag-row {
    font-size: 0.85rem !important;
  }

  .ag-menu-option-active {
    background-color: #2661ad;
    color: white;
  }

  .ag-header-group-cell-label {
    justify-content: center;
  }

  .ag-header-cell {
    &[col-id^='Lost_'] {
      background: #ED7D31;
      font-weight: 600;
      color: white;
      font-size: 0.85rem;
    }

    &[col-id^='%Lost_'] {
      background: #ED7D31;
      font-weight: 600;
      color: white;
      font-size: 0.85rem;
    }

    &[col-id^='Won_'] {
      background: #70AD47;
      font-weight: 600;
      color: white;
      font-size: 0.85rem;
    }

    &[col-id^='%Won_'] {
      background: #70AD47;
      font-weight: 600;
      color: white;
      font-size: 0.85rem;
    }

    &[col-id^='Targeted_'] {
      background: #8497B0;
      font-weight: 600;
      color: white;
      font-size: 0.85rem;
    }

    &[col-id^='%Total_'] {
      background: #8497B0;
      font-weight: 600;
      color: white;
      font-size: 0.85rem;
    }
  }

  .ag-header-group-cell {
    &[col-id='1_0'] {
      background: #4471C4;
      color: white;
    }

    &[col-id='2_0'] {
      background: #5B9BD5;
      color: white;
    }

    &[col-id='3_0'] {
      background: #4471C4;
      color: white;
    }

    &[col-id='4_0'] {
      background: #5B9BD5;
      color: white;
    }

    &[col-id='5_0'] {
      background: #4471C4;
      color: white;
    }

    &[col-id='6_0'] {
      background: #5B9BD5;
      color: white;
    }


    &[col-id='7_0'] {
      background: #4471C4;
      color: white;
    }
  }

  .ag-row-pinned {
    .ag-cell {
      &[col-id='industry'] {
        font-weight: bold;
      }

      &[col-id^='Lost_'] {
        background: #ED7D31;
        color: white;
      }

      &[col-id^='%Lost_'] {
        background: #ED7D31;
        color: white;
      }

      &[col-id^='Won_'] {
        background: #70AD47;
        color: white;
      }

      &[col-id^='%Won_'] {
        background: #70AD47;
        color: white;
      }

      &[col-id^='Targeted_'] {
        background: #8497B0;
        color: white;
      }

      &[col-id^='%Total_'] {
        background: #8497B0;
        color: white;
      }
    }
  }
}