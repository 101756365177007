.label_PizzaBOX {
    fill: white;
    font-size: 14px;
    font-family: sans-serif;
    text-anchor: middle;
    dominant-baseline: central;
  }

  .tooltip_PizzaBOX {
    position: absolute;
    background-color: white;
    padding: 5px;
    font-size: 0.8rem;
    border: 1px solid transparent; /* Default transparent border */
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.2s;
  }