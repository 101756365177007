.container-fav {
  display: flex;
  /* touch-action: none; */
  /* width: 63%; */
  margin: 1rem auto;
  /* margin-top: 10%; */
}

/* For Droping Cards Position */
.dropzone {
  /* flex: 1; */
  /* height: 50rem; */
  /* border: 1px solid rgba(143, 46, 46, 0.1);
  border-radius: 1rem; */
}

.dropzone.left {
  margin-left: 10px;
}

.grid-item {
  /* padding: 10px; */
  width: 100%;
  height: 100%;
  /* box-sizing: border-box; */
  cursor: grab;
}
